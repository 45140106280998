import React from 'react';
import { graphql } from 'gatsby';
import Image from "gatsby-image"
import Layout from "../components/layout"
import { FaPrint, FaStar,FaMapMarkerAlt, FaUserShield, FaRegClock, FaShieldAlt } from "react-icons/fa"
import BlockContent from '../components/block-content'
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"
import UspSection from "../components/uspSection"
import Helmet from 'react-helmet'
import PortableText from '@sanity/block-content-to-react'
import $ from 'jquery'
import CTA from "../components/cta"

function getUrlVars(){
    var vars = [], hash;
    if(typeof window !== 'undefined'){
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
    }
    return vars;
  }
  var city = getUrlVars()["city"];

  if (city !== undefined){
    let cityDash = city;
    cityDash = cityDash.replace(/-/g, ' ');

      var cityplace = " in " + cityDash;
      var citytitle = cityDash+"'s";
  } 

export const query = graphql`
    query reviewsQuery {
        sanityPages(slug: {current: {eq: "reviews"}}) {
            pagetitle
            heroheader
            _rawHerocopy
            slug {
                current
            }
            usp1{
                uspTitle
                uspText
                icon
            }
            usp2{
                uspTitle
                uspText
                icon
            }
            usp3{
                uspTitle
                uspText
                icon
            }
            _rawFirstcopy
            _rawServices
            coupon {
                title
                type
            }
            heroimage {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            serviceimage {
                asset {
                    fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                    }
                }
            }
        }
        sanityCompanyInfo {
            companyname
            companyTagline
            primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
            tertiarycolor{
              hex
            }
            tertiaryaltcolor{
              hex
            }
        }
        allSanitySocial{
          edges{
            node{
              socialproofTitle
              socialproofNumber
              }
            }
          }
        allSanityReviews{
            edges{
                node{
                    review
                    author
                    review_source {
                        review_source
                        logo {
                          asset {
                            fluid {
                                ...GatsbySanityImageFluid
                              src
                            }
                          }
                        }
                      }
                }
            }
        }
    }
`

const now = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const today = days[now.getDay()];
function printCoupon() {
    if(typeof window !== 'undefined'){
        window.print();
    }
  }
/* ADD CITY TO OUR SERVICES LINK */

    function changeActive(){
        $(".form").toggleClass("expanded");
        $('body').toggleClass('formExpanded');
      }

export default ({ data }) => (
    <Layout>
        <Helmet>
            <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>
            
        </Helmet>
        <div className="popupForm"><Form /></div>

        <div className="row heroRow">
        <div className="leftSection">
            <div className="leftSectionText">
                <p className="tagline_marker" style={{color: data.sanityCompanyInfo.tertiarycolor.hex, textTransform: 'uppercase'}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline} {cityplace}</p>
                <span className="heroBlockTitle"><h2>{data.sanityPages.heroheader} {cityplace}</h2></span>
                <PortableText blocks={data.sanityPages._rawHerocopy} />
                <a href="#" onClick={changeActive} 
                style={{ backgroundColor: data.sanityCompanyInfo.accentcolor.hex, borderColor: data.sanityCompanyInfo.accentcolor.hex }}
                > Schedule Service Today</a>
            </div>
        </div>
        <div className="rightSection">
            <BackgroundImage 
            style={{height: "100%", backgroundSize: "cover"}}
            fluid={data.sanityPages.heroimage.asset.fluid}>
            </BackgroundImage>
        </div>
    </div>

        <div className="reviewsPage"  style={{backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex }}>
            <div className="container">
                <div className="row reviewRow">
                    
                        {data.allSanityReviews.edges.map(({ node: reviews }) => (
                            <div className="review">
                                <div className="stars"><FaStar style={{ color: '#fcba03' }} /><FaStar style={{ color: '#fcba03' }} /><FaStar style={{ color: '#fcba03' }} /><FaStar style={{ color: '#fcba03' }} /><FaStar style={{ color: '#fcba03' }} /></div>
                                <p className="reviewText">{reviews.review}</p>
                                <div className="review-meta">
                                    <Image className="review-logo" location=""
                                    fluid={reviews.review_source.logo.asset.fluid}
                                    style={{ height: "40px", width: "40px" }}
                                    alt="Company Logo"
                                    />
                                    <div className="right-column">
                                        <p className="author">{reviews.author}</p>
                                    
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                    <div className="seemorereviews">
                        <a href="/" target="_blank">See More Reviews</a>
                    </div>
            </div>
            <div className="socialProof" style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex, color:"#fff" }}>
                <div className="container">
                    <div className="socialBlurb">
                        {data.allSanitySocial.edges.map(({ node: social }) => (
                    <div className="blurp">
                        <div className="socialInfo">
                        <h3>{social.socialproofNumber}</h3>
                        <p>{social.socialproofTitle}</p>
                        </div>
                    </div>
                        ))}
                    </div>
                </div>
            </div>   

            <div className="row servicesRow">
                <div className="leftSection">
                    <BackgroundImage
                        style={{ height: "100%" }}
                        fluid={data.sanityPages.serviceimage.asset.fluid}>
                    </BackgroundImage>
                </div>
                <div className="rightSection" style={{ backgroundColor: data.sanityCompanyInfo.primarycolor.hex }}>
                    <span className="rightSectionTitle"><h2 style={{ color: data.sanityCompanyInfo.tertiaryaltcolor.hex }}>Why You Should Choose {data.sanityCompanyInfo.companyname}</h2></span>
                    <span className="rightSectionText" style={{ color: data.sanityCompanyInfo.tertiaryaltcolor.hex }}>
                        <BlockContent blocks={data.sanityPages._rawServices} />
                    </span>
                    <div className="ourServiceUSP">
                        <div className="three-columns">
                            <div className="column column1">
                                <i className={"fa fa-" + data.sanityPages.usp1.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp1.uspTitle}</h2>
                            </div>
                            <div className="column column2">
                                <i className={"fa fa-" + data.sanityPages.usp2.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp2.uspTitle}</h2>
                            </div>
                            <div className="column column3">
                                <i className={"fa fa-" + data.sanityPages.usp3.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp3.uspTitle}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <CTA />
    </Layout>
)